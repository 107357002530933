var prices_normal = {
    "angebot-0-20": "15,90",
    "angebot-21-30": "10,90",
    "angebot-31-40": "10,90",
    "angebot-41-50": "10,90",
    "angebot-51-60": "10,90",
    "angebot-ue60": "10,90",
};
var prices_bundle = {
    "angebot-0-20": "16,20",
    "angebot-21-30": "14,10",
    "angebot-31-40": "14,10",
    "angebot-41-50": "14,10",
    "angebot-51-60": "14,10",
    "angebot-ue60": "14,10",
};

function addCheckboxLogic(idMZ, idZ100, idZ80, idButton, idScrollAnchor, screen_id) {    
    var checkboxMehrZahnvorsorge = document.getElementById(idMZ);
    var checkboxZahn100 = document.getElementById(idZ100);
    var checkboxZahn80 = document.getElementById(idZ80);
    var button = document.getElementById(idButton);
    var scrollAnchor = document.getElementById(idScrollAnchor);
    
    // For Dynamic Bundle Pricing
    var xpath = ".//u[text()='price_mz']"
    var priceMZLabel = document.evaluate(xpath, checkboxMehrZahnvorsorge.closest('.elements-container-inner'), null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;

    if (!checkboxMehrZahnvorsorge) {
        console.log("Element 'checkboxMehrZahnvorsorge' with ID '" + idMZ + "' not found");
        return;
    }
    if (!checkboxZahn100) {
        console.log("Element 'checkboxZahn100' with ID '" + idZ100 + "' not found");
        return;
    }
    if (!checkboxZahn80) {
        console.log("Element 'checkboxZahn80' with ID '" + idZ80 + "' not found");
        return;
    }
    if (!button) {
        console.log("Element 'button' with ID '" + idButton + "' not found");
        return;
    }
    if (!scrollAnchor) {
        console.log("Element 'scrollAnchor' with ID '" + idScrollAnchor + "' not found");
        return;
    }
    
    function scrollToCheck() {
        const yOffset = -100;
        const y = scrollAnchor.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
    }
    checkboxZahn80.addEventListener("change", function() {
        if (this.checked) {
            scrollToCheck();
        }
    });
    checkboxZahn100.addEventListener("change", function() {
        if (this.checked) {
            scrollToCheck();
        }
    });
    
    function toggleButton() {
        if (!checkboxZahn100.checked && !checkboxZahn80.checked && !checkboxMehrZahnvorsorge.checked) {
            button.disabled = true;
            button.parentElement.classList.add("cursor-not-allowed");
        } else {
            button.disabled = false;
            button.parentElement.classList.remove("cursor-not-allowed");
        }
        
        var price_string = prices_normal[screen_id];
        if (checkboxZahn100.checked || checkboxZahn80.checked) {
            price_string = prices_bundle[screen_id];
        }
        price_string += "\u20AC mtl."
        if (priceMZLabel) {
            priceMZLabel.innerText = price_string;
        }
    }
    
    
    checkboxZahn100.addEventListener("change", () => {
        if (checkboxZahn100.checked) {
            checkboxZahn80.checked = false;
        }
        toggleButton();
    });
    checkboxZahn80.addEventListener("change", () => {
        if (checkboxZahn80.checked) {
            checkboxZahn100.checked = false;
        }
        toggleButton();
    });
    checkboxMehrZahnvorsorge.addEventListener("change", toggleButton);
    
    toggleButton();
    
}

window.addEventListener("heyflow-init", (event) => {
    addCheckboxLogic("id-64c1af3b", "id-16a34915", "checkbox-d58b7ae6", "button-0db45210", "scroll-to-mehrzahn-0-20", "angebot-0-20");
    addCheckboxLogic("id-cb208fe5", "id-ffacefde", "id-a72ca421", "id-053def0b", "scroll-to-mehrzahn-21-30", "angebot-21-30");
    addCheckboxLogic("id-63e8eed1", "id-c14136b7", "id-043679de", "id-80ff9368", "scroll-to-mehrzahn-31-40", "angebot-31-40");
    addCheckboxLogic("id-f0d749a5", "id-c7f69cfc", "id-0edbee85", "id-a0df5913", "scroll-to-mehrzahn-41-50", "angebot-41-50");
    addCheckboxLogic("id-75e2dada", "id-9d70bb53", "id-0473999f", "id-4ef9bf07", "scroll-to-mehrzahn-51-60", "angebot-51-60");
    addCheckboxLogic("id-c273d289", "id-c37079fe", "id-c3363fa6", "id-b6ae6c0d", "scroll-to-mehrzahn-ue60", "angebot-ue60");
});